import {
    getCanonicalPathByDomini,
    getFormatData,
    getUrlCanonical,
    hasAgrupadoraEvaMain,
    hasSX3Canal,
    isCanonicalProgramException,
    getPageTitle
} from '@portal-internet/core';
import { getImatge } from 'components/3cat/Imatge';
import { useProducte } from 'hooks/3cat/useProducte';
import { useViewport } from 'hooks/useViewport';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { getCropByViewport } from 'utils/imatgesHelper';

const _get3catCanonical = (props, router) => {
    const { nombonic, routePath } = props;
    let url;

    if (routePath) {
        const { id } = router.query;
        url = getUrlCanonical({ slug: nombonic, id, routePath });
    } else {
        url = router.asPath;
        const indexParams = url.indexOf('?');
        url = indexParams > 0 ? url.substring(0, indexParams) : url;
    }

    return 'https://www.ccma.cat' + url;
};

const _getSX3Canonical = (props, router) => {
    const { nombonic, tipus } = props;

    if (tipus === 'video.other') {
        const { id } = router.query;
        return `https://www.ccma.cat/tv3/sx3/${nombonic}/video/${id}/`;
    }
    return `https://www.ccma.cat/tv3/sx3/${nombonic}/`;
};

const _getNoticiaCanonical = (props, router) => {
    const { domini, programesTv, programesRadio } = props;
    const urlCanonical = _get3catCanonical(props, router);
    const exception = isCanonicalProgramException({ programesTv, programesRadio, domini });

    if (!exception && (programesTv || programesRadio)) return urlCanonical;

    const path = getCanonicalPathByDomini(domini);

    return urlCanonical.replace('/3cat/', `/${path}/`);
};

const _getUrlCanonical = (props, router) => {
    const { canonical, canal, layout } = props;

    if (layout === 'noticia') return _getNoticiaCanonical(props, router);
    if (hasSX3Canal(canal)) return _getSX3Canonical(props, router);

    return canonical !== undefined ? canonical : _get3catCanonical(props, router);
};

const _getNoIndex = (unavailableAfter, estat) => {
    const now = new Date();
    unavailableAfter = unavailableAfter ? unavailableAfter < now : false;
    estat = estat ? estat === 'DESP' : false;
    return unavailableAfter || estat;
};

export default function Metadades(props) {
    const {
        titol,
        imatges,
        sufix,
        prefix,
        imagePreview,
        tipus,
        estat,
        desindexar,
        dataCaducitat,
        logicaFaldo,
        canal,
        agrupacions,
        hasToPreloadBackgroundPoster,
        urlRSS,
        publicarPodcast,
        titolPrograma
    } = props;
    const descripcio = props.descripcioPromo || props.descripcio;
    const router = useRouter();
    const { isWebviewActive } = useProducte();
    const isEmbed = router?.asPath?.includes('/embed/');
    const urlCanonical = (!isWebviewActive || isEmbed) && _getUrlCanonical(props, router);
    const { viewport } = useViewport();

    const getImatgeXXSS = () => {
        let img = getImatge(imatges, 'web_xxss', null, null, !!logicaFaldo);

        if (img) {
            if (logicaFaldo === 'video') {
                const faldo = hasSX3Canal(canal) ? 'sx3' : hasAgrupadoraEvaMain(agrupacions) ? 'eva' : '3cat';
                img = img + '?strip=' + faldo + '-1200x628';
            } else if (logicaFaldo === 'audio' || logicaFaldo === 'noticia') {
                img = img + '?strip=3cat-1200x628';
            }
        }
        return img;
    };

    const imgxxss = getImatgeXXSS();
    const titolComplet = getPageTitle(prefix, titol, sufix);
    const unavailableAfter = dataCaducitat ? getFormatData(dataCaducitat) : false;
    const noIndex = _getNoIndex(unavailableAfter, estat) || isWebviewActive || desindexar;

    const playerBackground = hasToPreloadBackgroundPoster
        ? getImatge(imatges, 'web_player_poster_background', null, getCropByViewport(viewport), null)
        : null;
    return (
        <Head>
            <title>{titolComplet}</title>
            <meta name='description' content={descripcio} />
            <meta name='author' content='CCMA' />
            {/**Facebook*/}
            <meta property='og:type' content={tipus} />
            <meta property='og:locale' content='ca_ES' />
            <meta property='og:site_name' content='3Cat' />
            <meta property='og:title' content={titolComplet} />
            <meta property='og:description' content={descripcio} />
            {imgxxss && <meta property='og:image' content={imgxxss}></meta>}
            <meta property='og:url' content={urlCanonical} />
            {/**Twitter*/}
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={titolComplet} />
            <meta name='twitter:description' content={descripcio} />
            <meta name='twitter:site' content='@som3cat'></meta>
            {imgxxss && <meta name='twitter:image' content={imgxxss}></meta>}
            {imgxxss && <meta name='twitter:image:src' content={imgxxss}></meta>}
            <meta name='twitter:url' content={urlCanonical} />
            {/*Canonical*/}
            {urlCanonical && <link rel='canonical' href={urlCanonical} />}
            {imagePreview && <meta name='robots' content='max-image-preview:large'></meta>}
            {noIndex && <meta name='robots' content='noindex'></meta>}
            {unavailableAfter && <meta name='googlebot' content={'unavailable_after: ' + unavailableAfter}></meta>}
            {hasToPreloadBackgroundPoster && playerBackground && <link rel='preload' as='image' href={playerBackground}></link>}
            {publicarPodcast && urlRSS && <link type='application/rss+xml' rel='alternate' title={titolPrograma} href={urlRSS} />}
        </Head>
    );
}

Metadades.defaultProps = {
    titol: 'Totes les sèries, programes i pel·lícules de TV3 i CatRàdio',
    descripcio:
        'Entra al servei online 3cat per veure les sèries, programes i pel·lícules de TV3 i Catalunya Ràdio, a la carta i en directe. Amb contingut exclusiu.',
    tipus: 'website',
    imagePreview: false,
    publicarPodcast: false,
    titolPrograma: ''
};
